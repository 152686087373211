<template>
  <div class="btn-signin-google-large unselect" @click="login">
    <img src="/static/icon/ic_google_org.svg">
    <div class="main body2-medium">빠른 가입</div>
  </div>
</template>

<script>
  export default {
    name: "BtnSigninGoogle",
    methods: {
      login() {
        const url = encodeURIComponent(`${this.backendUrl}/auth/partner/signin/google/launchpack`);

        const scope = "https://www.googleapis.com/auth/userinfo.email " +
                "https://www.googleapis.com/auth/userinfo.profile";
        location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=850496551254-9e5rep63vauttf5586o78fvfrts5gnfi.apps.googleusercontent.com&response_type=code&redirect_uri=${url}&scope=${scope}`
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .btn-signin-google-large
    width 232px
    padding 13px 0
    display flex
    align-items center
    justify-content center
    gap 10px
    border 1px solid #ddd
    border-radius 50px
</style>
