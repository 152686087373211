<template>
  <div class="container-top bg">
    <partner-signup-comp v-if="status === 0"></partner-signup-comp>
    <user-merge :signUser="signUser" v-else-if="status === 1"
                @prev="status=0" @next="status=2"></user-merge>
    <merge-done v-else-if="status === 2"></merge-done>
    <add-info v-else-if="status === 3"></add-info>
  </div>
</template>

<script>

  import PartnerSignupComp from "./SignupComp/PartnerSignupComp";
  import UserMerge from "./SignupComp/UserMerge";
  import AddInfo from "./SignupComp/PartnerAddInfo";
  import MergeDone from "./SignupComp/MergeDone";
  export default {
    name: "PartnerSignup",
    components: {
      MergeDone,
      AddInfo,
      UserMerge,
      PartnerSignupComp

    },
    created() {
      if(this.$route.query.login_type) {
        let already_type = this.$route.query.already_type;
        if(already_type) {
          // 가입된 SNS 계정으로 로그인 한 경우 signin 페이지로 이동
          if(already_type.indexOf(this.$route.query.login_type) >-1) {
            this.$router.replace(this.$route.fullPath.replace('partner_signup', 'signin'));
          }
          // 가입 안된 SNS 계정으로 로그인 한 경우
          else {
            this.signUser.kakao = false;
            this.signUser.naver = false;
            this.signUser.facebook = false;
            this.signUser.google = false;
            already_type.split(',').forEach(key => {
              this.signUser[key] = true;
            });
            this.signUser.email = this.$route.query.email;
            this.status = 1;
          }
        } else {
          // 소셜로그인 후 접근시 필수 정보 입력 표시
          this.status = 3;
        }
      } else {
        this.status = 0;
      }
      
    },
    data() {
      return {
        status: -1,
        signUser: {
          email: 'test@test.com',
          kakao: false,
          naver: false,
          facebook: false,
          google: false
        }
      }
    },
    methods: {

    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .bg
    background-color $gray4
    display flex
    justify-content center
    align-items flex-start
</style>
