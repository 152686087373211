<template>
  <div class="signup-comp">
    <div class="pc signup-container">
      <div class="signup-wrapper">
        <div class="subtitle3 main" style="margin-bottom: 40px">
          이미 가입한 계정이 있습니다.
          <div class="body2-medium main margin-top-16">
            <div class="body2">가입아이디</div>
            <div class="sns flex-center margin-top-4" style="gap:6px">
              <img v-if="signUser.kakao" src="/static/icon/ic_kakao_circle.svg" />
              <img v-if="signUser.naver" src="/static/icon/ic_naver_circle.svg" />
              <img v-if="signUser.facebook" src="/static/icon/ic_facebook_circle.svg" />
              <img v-if="signUser.google" src="/static/icon/ic_google_circle.svg" />
              <div>{{ signUser.email }}</div>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="body4 main">
          <span class="body4-bold">{{ alreadyType }}</span> 계정과 자동연결을 원하시나요?
        </div>
        <button class="button is-primary body2-medium" style="margin:20px 0 40px 0;width:100%" @click="clickMerge">
          연결하기
        </button>
        <div class="flex-center">
          <div class="body4 main unselect" @click="$emit('prev')">
            다른방법으로 <span class="primary">{{ userType }} 가입</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile signup-container">
      <div class="signup-wrapper">
        <div class="h7 main" style="margin-bottom: 40px">
          이미 가입한 계정이 있습니다.
          <div class="body2-medium main margin-top-16">
            <div class="body2">가입 아이디</div>
            <div class="sns flex-center margin-top-4" style="gap:6px">
              <img v-if="signUser.kakao" src="/static/icon/ic_kakao_circle.svg" />
              <img v-if="signUser.naver" src="/static/icon/ic_naver_circle.svg" />
              <img v-if="signUser.facebook" src="/static/icon/ic_facebook_circle.svg" />
              <img v-if="signUser.google" src="/static/icon/ic_google_circle.svg" />
              <div>{{ signUser.email }}</div>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="body4 main">
          <span class="body4-bold">{{ alreadyType }}</span> 계정과 자동연결을 원하시나요?
        </div>
        <button class="button is-primary body2-medium" style="margin:20px 0 40px 0;width:100%" @click="clickMerge">
          연결하기
        </button>
        <div class="flex-center">
          <div class="body4 main unselect" @click="$emit('prev')">
            다른방법으로 <span class="primary">{{ userType }} 가입</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserMerge',
  props: {
    signUser: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    alreadyType() {
      let msg = '';
      let type = this.$route.query.already_type;
      if (type) {
        if (type === 'email') {
          msg = '이메일';
        } else {
          type = type.replace('email,', '');
          msg = {
            kakao: '카카오',
            naver: '네이버',
            facebook: '페이스북',
          }[type];
        }
        // if (msg !== '') {
        //   msg = '#' + msg;
        // }
      }
      return msg;
    },
    userType() {
      return this.$route.path.indexOf('partner') > -1 ? '파트너' : '회원';
    },
  },
  methods: {
    clickMerge() {
      let params = {};
      params[`${this.$route.query.login_type}_id`] = this.$route.query.user_id;
      this.$axios.defaults.headers.common['Authorization'] = 'Token ' + this.$route.query.token;
      this.$axios.patch(`user/${this.$route.query.lp_user_id}`, params).then((res) => {
        this.$store.commit('setUser', res.data.user);
        this.setAuthHeader();
        if (this.$store.getters.trial) {
          this.toast('기존계정과 자동 연동되었습니다.');
          this.$router.push('/service_survey');
        } else {
          this.$emit('next');
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.signup-container
  text-align center

.signup-wrapper
  border 1px solid $gray2
  border-radius 12px
  background-color white
  padding 40px

.divider
  width 100%
  height 1px
  background-color $gray1
  margin-bottom 40px

.sns
  img
    width 20px
    height 20px

@media (min-width:1025px)
  .signup-container
    margin-top 80px
    width 448px

@media (max-width:1024px)
  .signup-comp
    width 100%
    padding 40px 16px
  .signup-container
    width 100%

  .signup-wrapper
    padding 32px 20px
</style>
